import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ClientService from "../../services/client.service";
const clientService = new ClientService();

export const getClientInfo = createAsyncThunk(
    'getClientInfo',
    async (clientId) => {
        const response = await clientService.getClientInfo(clientId);
        return response.data;
    }
);

export const getClientsList = createAsyncThunk(
    'getClientsList',
     async () =>{
        const response = await clientService.getClientsList();
        return response.data;
    }
);
export const createNewClient = createAsyncThunk(
    'createNewClient',
     async (data) =>{
        const response = await clientService.createNewClient(data);
        return response.data;
    }
);
export const editClient = createAsyncThunk(
    'editClient',
     async (data) =>{
        const response = await clientService.editClient(data.clientId, data.body);
        return response.data;
    }
);
export const deleteClient = createAsyncThunk(
    'deleteClient',
     async (clientId) =>{
        const response = await clientService.deleteClient(clientId);
        return response.data;
    }
);
/** Membership Methods */
export const createNewMembership = createAsyncThunk(
    'createNewMembership',
     async (data) =>{
        const response = await clientService.createNewMembership(data.clientId,data.body);
        return response.data;
    }
);
export const getMembershipList = createAsyncThunk(
    'getMembershipList',
     async (clientId) =>{
        const response = await clientService.getMembershipList(clientId);
        return response.data;
    }
);
export const getMembershipInfo = createAsyncThunk(
    'getMembershipInfo',
    async ({clientId,membershipId}) => {
        const response = await clientService.getMembershipInfo(clientId, membershipId);
        return response.data;
    }
);
export const editMembership = createAsyncThunk(
    'editMembership',
     async (data) =>{
        const response = await clientService.editMembership(data.clientId, data.membershipId, data.body);
        return response.data;
    }
);
export const deleteMembership = createAsyncThunk(
    'deleteMembership',
     async ({clientId,membershipId}) =>{
        const response = await clientService.deleteMembership(clientId,membershipId);
        return response.data;
    }
);

/** Payment Methods */
export const createNewPayment = createAsyncThunk(
    'createNewPayment',
     async (data) =>{
        const response = await clientService.createNewPayment(data.clientId, data.body);
        return response.data;
    }
);
export const getPaymentList = createAsyncThunk(
    'getPaymentList',
     async (clientId) =>{
        const response = await clientService.getPaymentList(clientId);
        return response.data;
    }
);
export const getNotifyPaymentList = createAsyncThunk(
    'getNotifyPaymentList',
     async () =>{
        const response = await clientService.getNotifyPaymentList();
        return response.data;
    }
);
export const getPaymentInfo = createAsyncThunk(
    'getPaymentInfo',
    async (data) => {
        const response = await clientService.getPaymentInfo(data.clientId, data.paymentId);
        return response.data;
    }
);
export const editPayment = createAsyncThunk(
    'editPayment',
     async (data) =>{
        const response = await clientService.editPayment(data.clientId, data.paymentId, data.body);
        return response.data;
    }
);
export const deletePayment = createAsyncThunk(
    'deletePayment',
     async (data) =>{
        const response = await clientService.deletePayment(data.clientId, data.paymentId);
        return response.data;
    }
);

export const updateClientAttendance = createAsyncThunk(
    'updateClientAttendance',
     async (data) =>{
        const response = await clientService.updateClientAttendance(data.clientId, data.text);
        return response.data;
    }
);

export const getClientAttendance = createAsyncThunk(
    'getClientAttendance',
     async (data) =>{
        const response = await clientService.getClientAttendance(data.clientId, data.startDate, data.endDate);
        return response.data;
    }
);

const initialState = {
    clients: [],
    notifyPayments: [],
    status: 'idle',
    error: false,
}

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers:{
        updateClients(state, action){
            state.clients = action.payload;
        },
        updateNotifyPayments(state, action){
            state.notifyPayments = action.payload;
        },
    },
    extraReducers: (builder) =>{
        builder
        .addCase(getClientsList.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getClientsList.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (!action.payload.hasError) {
                state.clients = action.payload.data.clients;
            }
        })
        .addCase(getClientsList.rejected, (state, action) => {
            state.status = 'failed';
            state.error = true;
        })
        .addCase(getNotifyPaymentList.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getNotifyPaymentList.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if (!action.payload.hasError) {
                state.notifyPayments = action.payload.data.payments;
            }
        })
        .addCase(getNotifyPaymentList.rejected, (state, action) => {
            state.status = 'failed';
            state.error = true;
        })
    }
});

export const { updateClients, updateNotifyPayments } = clientSlice.actions;
export default clientSlice;
