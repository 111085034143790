import Service from "./service";

class ClientService extends Service{
    constructor(){
        super()
    }

    /** client services */
    async getClientsList(){
        return await this.ApiClient.get('/clients');
    }
    async createNewClient(data) {
        return await this.ApiClient.post('/clients/new', data);
    }
    async editClient(clientId, data) {
        return await this.ApiClient.put(`/clients/${clientId}/edit`, data);
    }
    async deleteClient(clientId) {
        return await this.ApiClient.delete(`/clients/${clientId}/delete`);
    }
    async getClientInfo(clientId) {
        return await this.ApiClient.get(`/clients/${clientId}`);
    }

    /** Membership Services */
    async createNewMembership(clientId,body) {
        return await this.ApiClient.post(`/clients/${clientId}/memberships/new`, body);
    }
    async getMembershipList(clientId){
        return await this.ApiClient.get(`/clients/${clientId}/memberships`);
    }
    async getMembershipInfo(clientId,membershipId) {
        return await this.ApiClient.get(`/clients/${clientId}/memberships/${membershipId}`);
    }
    async editMembership(clientId,membershipId, data) {
        return await this.ApiClient.put(`/clients/${clientId}/memberships/${membershipId}/edit`, data);
    }
    async deleteMembership(clientId,membershipId) {
        return await this.ApiClient.delete(`/clients/${clientId}/memberships/${membershipId}/delete`);
    }

    /** Payment Services */
    async createNewPayment(clientId, body) {
        return await this.ApiClient.post(`/clients/${clientId}/payments/new`, body);
    }
    async getPaymentList(clientId){
        return await this.ApiClient.get(`/clients/${clientId}/payments`);
    }
    async getNotifyPaymentList(){
        return await this.ApiClient.get(`/clients/notifyPaymentList`);
    }
    async getPaymentInfo(clientId, paymentId){
        return await this.ApiClient.get(`/clients/${clientId}/payments/${paymentId}`);
    }
    async editPayment(clientId, paymentId, data) {
        return await this.ApiClient.put(`/clients/${clientId}/payments/${paymentId}/edit`, data);
    }
    async deletePayment(clientId, paymentId) {
        return await this.ApiClient.delete(`/clients/${clientId}/payments/${paymentId}/delete`);
    }
    async updateClientAttendance(clientId,text) {
        return await this.ApiClient.post(`/clients/${clientId}/updateClientAttendance?text=${text}`);
    }
    async getClientAttendance(clientId, startDate, endDate) {
        return await this.ApiClient.get(`/clients/${clientId}/getClientAttendance?startDate=${startDate}&endDate=${endDate}`);
    }
}

export default ClientService; 
