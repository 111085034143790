import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/userSlice";
import adminSlice from "./features/adminSlice";
import clientSlice from "./features/clientSlice";

const store=configureStore({
    reducer:{
        userStore: userSlice.reducer,
        adminStore: adminSlice.reducer,
        clientStore: clientSlice.reducer,
    },
});

export default store;