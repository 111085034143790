import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "../../services/user.service";
const userService = new UserService();

export const initialFetch = createAsyncThunk(
    'initialFetch',
    async () => {
          const response = await userService.initialFetch();
          return response.data;
        }
);
export const userLogin = createAsyncThunk(
    'userLogin',
    async (data) => {
          const response = await userService.userLogin(data);
          return response.data;
        }
);

export const userLogout = createAsyncThunk(
    'userLogout',
    async (data) => {
          const response = await userService.userLogout(data);
          return response.data;
        }
);
export const updateUserInfo = createAsyncThunk(
    'updateUserInfo',
    async (data) => {
          const response = await userService.updateUserInfo(data);
          return response.data;
        }
);
export const resetPassword = createAsyncThunk(
    'resetPassword',
    async (data) => {
          const response = await userService.resetPassword(data);
          return response.data;
        }
);

const initialstate = {
    isAuthenticated: false,
    token: null,
    user: {},
    isAdmin: false,
    isManager: false,
    status: 'idle',
    error: null,
    isLoading: true,
    showLoader: false,
    showBackDropLoader: false,
}

const userSlice = createSlice({
    name: "user",
    initialState: initialstate,
    reducers: {
        updateAuthStatus(state, action) {
            state.isAuthenticated = action.payload;
        },
        updateToken(state, action) {
            state.token = action.payload;
        },
        updateUser(state, action) {
            state.user = action.payload;
        },
        updateIsAdmin(state, action) {
            state.isAdmin = action.payload;
        },
        updateIsManager(state, action) {
            state.isManager = action.payload;
        },
        updateAuthSliceErrorStatus(state, action) {
            state.error = action.payload;
        },
        updateRequestStatus(state, action) {
            state.status = action.payload;
        },
        updateShowLoader(state, action) {
            state.showLoader = action.payload;
        },
        updateShowBackDropLoader(state, action) {
            state.showBackDropLoader = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(initialFetch.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
        state.showLoader = true;
        })
        .addCase(initialFetch.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.showLoader = false;
            if(!action.payload.hasError){
                state.isAuthenticated = action.payload.data.isAuthenticated;
                state.token = action.payload.data.token;
                state.user = action.payload.data.user;
                state.isAdmin = action.payload.data.isAdmin;
                state.isManager = action.payload.data.isManager;
                state.isLoading = false;
            }
        })
        .addCase(initialFetch.rejected, (state, action) => {
        state.status = 'failed';
        state.error = true;
        state.isLoading = false;
        state.showLoader = false;
        })
        .addCase(userLogout.pending, (state) => {
        state.status = 'loading';
        })
        .addCase(userLogout.fulfilled, (state, action) => {
        state.status = 'succeeded';
            if(!action.payload.hasError){
                state.isAuthenticated = action.payload.data.isAuthenticated;
            }
        })
        .addCase(userLogout.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        })
    }
});

export const { updateAuthStatus, updateToken, updateUser, updateAuthSliceErrorStatus,
    updateRequestStatus, updateIsAdmin, updateIsManager, updateShowLoader, updateShowBackDropLoader } = userSlice.actions;
export default userSlice;