import Service from "./service";

class AdminService extends Service {
  constructor() {
    super();
  }

  async getUsersList() {
    return await this.ApiClient.get("/admin/users");
  }
  async createNewUser(data) {
    return await this.ApiClient.post("/admin/users/create", data);
  }
  async editUser(userId, data) {
    return await this.ApiClient.put(`/admin/users/${userId}/edit`, data);
  }
  async deleteUser(userId) {
    return await this.ApiClient.delete(`/admin/users/${userId}/delete`);
  }
  async getUserInfo(userId) {
    return await this.ApiClient.get(`/admin/users/${userId}`);
  }

  async getServicesList() {
    return await this.ApiClient.get(`/admin/services`);
  }
  async createNewService(data) {
    return await this.ApiClient.post(`/admin/services/new`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  async editService(serviceId, data) {
    return await this.ApiClient.put(`/admin/services/${serviceId}/edit`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  async deleteService(serviceId) {
    return await this.ApiClient.delete(`/admin/services/${serviceId}/delete`);
  }
  async getServiceInfo(serviceId) {
    return await this.ApiClient.get(`/admin/services/${serviceId}`);
  }
  async getAllDashboardData() {
    return await this.ApiClient.get(`/admin/dashboard`);
  }
  async getAttendanceChartData(date) {
    return await this.ApiClient.get(`/admin/getAttendanceChartData?date=${date}`);
  }
  async getServiceChartData() {
    return await this.ApiClient.get(`/admin/getServiceChartData`);
  }
}

export default AdminService;
