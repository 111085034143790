import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AdminService from "../../services/admin.service";
const adminService = new AdminService();

export const getUserInfo = createAsyncThunk("getUserInfo", async (userId) => {
  const response = await adminService.getUserInfo(userId);
  return response.data;
});
export const getUsersList = createAsyncThunk("getUsersList", async () => {
  const response = await adminService.getUsersList();
  return response.data;
});
export const createNewUser = createAsyncThunk("createNewUser", async (data) => {
  const response = await adminService.createNewUser(data);
  return response.data;
});
export const editUser = createAsyncThunk("editUser", async (data) => {
  const response = await adminService.editUser(data.userId, data.body);
  return response.data;
});
export const deleteUser = createAsyncThunk("deleteUser", async (userId) => {
  const response = await adminService.deleteUser(userId);
  return response.data;
});
export const getServiceInfo = createAsyncThunk(
  "getServiceInfo",
  async (serviceId) => {
    const response = await adminService.getServiceInfo(serviceId);
    return response.data;
  }
);
export const getServicesList = createAsyncThunk("getServicesList", async () => {
  const response = await adminService.getServicesList();
  return response.data;
});
export const createNewService = createAsyncThunk(
  "createNewService",
  async (data) => {
    const response = await adminService.createNewService(data);
    return response.data;
  }
);
export const editService = createAsyncThunk("editService", async (data) => {
  const response = await adminService.editService(data.serviceId, data.body);
  return response.data;
});
export const deleteService = createAsyncThunk(
  "deleteService",
  async (serviceId) => {
    const response = await adminService.deleteService(serviceId);
    return response.data;
  }
);
export const getAllDashboardData = createAsyncThunk(
  "getAllDashboardData",
  async () => {
    const response = await adminService.getAllDashboardData();
    return response.data;
  }
);
export const getAttendanceChartData = createAsyncThunk(
  "getAttendanceChartData",
  async (date) => {
    const response = await adminService.getAttendanceChartData(date);
    return response.data;
  }
);
export const getServiceChartData = createAsyncThunk(
  "getServiceChartData",
  async () => {
    const response = await adminService.getServiceChartData();
    return response.data;
  }
);

const initialState = {
  userInfo: {},
  users: [],
  services: [],
  error: null,
  status: "idle",
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    updateUsers(state, action) {
      state.users = state.users.filter(
        (user) => user._id !== action.payload.userId
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (!action.payload.hasError) {
          state.users = action.payload.data.users;
        }
      })
      .addCase(getUsersList.rejected, (state, action) => {
        state.status = "failed";
        state.error = true;
      })
      .addCase(getServicesList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getServicesList.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (!action.payload.hasError) {
          state.services = action.payload.data.services;
        }
      })
      .addCase(getServicesList.rejected, (state, action) => {
        state.status = "failed";
        state.error = true;
      })
      .addCase(getUserInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (!action.payload.hasError) {
          state.userInfo = action.payload.data.user;
        }
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = true;
      });
  },
});

export const { updateUsers, updateServices } = adminSlice.actions;
export default adminSlice;
