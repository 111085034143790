import {RotateLoader, MoonLoader} from "react-spinners";

const parentStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 2050,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'opacity 1s ease-in-out, visibility 1s ease-in-out',
};

const spinnerStyle = {
position: 'absolute',
top: '50%',
left: '50%',
msTransform: 'translate(-50%, -50%)',
transform: 'translate(-50%, -50%)',
zIndex: 2051,
transition: 'opacity 1s ease-in-out, visibility 1s ease-in-out',
};

export const Loader = ({size= 20, color= "#1d4ed8", show= true}) => {
  return (
    <div style={{...spinnerStyle, opacity: show ? 1 : 0}}>
        <RotateLoader color={color} size={size}/>
    </div>
    
  );
}

export const BackDropLoader = ({size= 20, color= "#1d4ed8", show= true}) => {
  return (
    <div  style={{ ...parentStyle, opacity: show ? 1 : 0 }}>
      <div style={spinnerStyle}>
        <RotateLoader color={color} size={size}/>
    </div>
    </div>
  );
}

export const spinLoader = ({size= 70, color= "#1d4ed8", show= true}) => {
  return (
      <div style={{...spinnerStyle, opacity: show ? 1 : 0}}>
        <MoonLoader color={color} size={size} speedMultiplier={1}/>
    </div>
  );
}