import 'bootstrap/dist/css/bootstrap.min.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Loader, BackDropLoader } from './components/common/Loader';

const Layout = lazy(() => import('./layouts/Layout'));
const PageNotFound = lazy(() => import('./components/common/404_Page'));
const LandingPage = lazy(() => import('./components/common/LandingPage'));
const Login = lazy(() => import('./components/auth/Login'));
const Dashboard = lazy(() => import('./components/admin/Dashboard'));
const UserList = lazy(() => import('./components/admin/users/UserList'));
const ClientList = lazy(() => import('./components/client/ClientList'));
const UserInfo = lazy(() => import('./components/common/UserInfo'));
const UserCreateForm = lazy(() => import('./components/admin/users/UserCreateForm'));
const UserEditForm = lazy(() => import('./components/admin/users/UserEditForm'));
const ClientCreateForm = lazy(() => import('./components/client/ClientCreateForm'));
const ClientEditForm = lazy(() => import('./components/client/ClientEditForm'));
const MembershipList = lazy(() => import('./components/client/MembershipList'));
const MembershipCreateForm = lazy(() => import('./components/client/MemberShipCreateForm'));
const ServiceList = lazy(() => import('./components/admin/services/ServiceList'));
const ServiceCreateForm = lazy(() => import('./components/admin/services/ServiceCreateForm'));
const MembershipEditForm = lazy(() => import('./components/client/MembershipEditForm'));
const PaymentList = lazy(() => import('./components/payments/PaymentList'));
const PaymentCreateForm = lazy(() => import('./components/payments/paymentCreateFrom'));

const App = () => {

  const { showLoader, showBackDropLoader } = useSelector(state => state.userStore);
  return (
    <>
      {showLoader && <Loader show={showLoader} />}
      {showBackDropLoader && <BackDropLoader show={showBackDropLoader} />}

      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<LandingPage />} />
              <Route path="login" element={<Login />} />
              <Route path="userInfo" element={<UserInfo />} />
              <Route path="notifications" element={<PaymentList />} />

              <Route path="admin">
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="users" element={<UserList />} />
                <Route path="users/create" element={<UserCreateForm />} />
                <Route path="users/:id" element={<UserEditForm />} />
                <Route path="services" element={<ServiceList />} />
                <Route path="services/new" element={<ServiceCreateForm />} />
                <Route path="services/:sid" element={<ServiceCreateForm />} />
              </Route>

              <Route path="clients" >
                <Route index element={<ClientList />} />
                <Route path=":cid/memberShips" element={<MembershipList />} />
                <Route path=":cid/memberShips/new" element={<MembershipCreateForm />} />
                <Route path=":cid/memberShips/:mid/edit" element={<MembershipEditForm />} />
                <Route path=":cid/payments/new" element={<PaymentCreateForm />} />
                <Route path=":cid/payments/:pid" element={<PaymentCreateForm />} />
                <Route path=":cid/payments" element={<PaymentList />} />
                <Route path="create" element={<ClientCreateForm />} />
                <Route path=":cid" element={<ClientEditForm />} />
              </Route>

            </Route>
            <Route path="/*" element={<PageNotFound />} />

          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  )
}

export default App;
