import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./index.scss";
import App from './App';
import {Provider} from "react-redux";
import store from "./redux/store";
import { initialFetch } from './redux/features/userSlice';

store.dispatch(initialFetch());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <Provider store={store}>
   <App/>
   </Provider>
);


